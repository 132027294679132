#root .coachcall-booking {
  max-width:40rem;

  position: relative;
  font-family: $family-sans-serif;
  font-size: 16px;
  border-radius: 0;
  background-color: $backgroundGrey;
  box-shadow: none;
  margin: 0 auto;
  z-index: 10;
  opacity: 1;
  color: $text;

  border:0;

  .fc-scroller{
      padding:0
  }
  .bookingjs-calendar{
      border:0
  }
  .bookingjs-loading{
      background:$backgroundGrey;
  }

  .fc-toolbar {
      display:none;
  }

  .fc-list-heading {
      font-size: $body-size;
      
  }

  .fc-list-heading-main{
      color:$dark;
      padding:0;
      width:100%;
      text-align:left;
      padding-top:3.5rem;
      font-weight:bold;
      margin-left:4px;

  }
  tr:first-child{
        .fc-list-heading-main{
            padding-top:0;
        }
  }
  .fc-list-heading-alt{
      color: $bodyGrey;
      padding:0;
      
  }
  tr.fc-list-item{
      color: $dark;




      @extend .has-text-weight-semibold;

      @extend .box;
      border: 0.5px solid rgba(0,0,0,0.1);
      box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1), 0 0 0 0.5px rgba(0,0,0,0.1);
      padding:1rem;
      margin: 1rem 0.25rem;


      td.fc-list-item-time{
          text-align: left;
          width:100%;
          padding:0;
          font-size: $body-size;

      }
  }

  .fc-widget-header{
      padding:0.5px 0 0 0;
      line-height:normal;
      ;
      
  }
  .fc-list-item:hover{

      td {
          transform: translateX(0.1em);
      }
  }

  &-closeicon {
    height: 15px;
    width: 15px;
  }

  &-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $backgroundGrey;
    z-index: 30;
    opacity: 0;
    transition: opacity 0.5s ease;
    border-radius: 4px;

    &.show {
      opacity: 1;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        height: 30px;
        width: 30px;
        animation: spin 0.6s infinite linear;

        path {
          fill: $primary;
        }
      }
    }

  }

  &-error {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $backgroundGrey;
    z-index: 31;
    opacity: 0;
    transition: opacity 0.5s ease;
    border-radius: 4px;

    &.show {
      opacity: 1;
    }

    &-inner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      overflow: scroll;
      max-height: 100%;
      padding: 30px;
      box-sizing: border-box;
      width: 100%;
    }


    &-text {
      font-size: 12px;
      color: $light;
      word-break: break-word;
      overgflow: scroll;

      &-messag {
        display: block;
      }
      &-context {
        display: block;
      }
    }

  }

  .fc-view-container {
      background-color: $backgroundGrey;
      box-shadow: 0px 0px 1px 9px $backgroundGrey;
      padding:1em;
  }
}

