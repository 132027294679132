@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css');

@import '~@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css';

// Greys
$text: #2D313F;
$light: #717071;
$bodyGrey: #808080;
$darkGrey: #3c3c3c;
$backgroundGrey: #FAFAFA;
$navbarGrey: #FAFAFA;
$progressGrey: #F5F5F5;
$lightishGrey: #ECECEC;
$indicatorYellow: #FFCC80;
$indicatorGreen: #A5D6A7;
$indicatorRed:#EF9A9A;

// Greens
$legacyPeoplecoachGreen: #228B22;
$focusGreen: #228B22;
$primary: #57BA82;
$primaryFoil: #64B864; // Used as a foil to the primary
$highlightGreen: rgba($primary, 0.5); // Yes, we want this.
$focusGreen: $legacyPeoplecoachGreen;

$family-sans-serif: "Poppins", sans-serif;
$custom-colors: (
                  "highlight-green": ($highlightGreen, $text),
                  "focus-green": ($focusGreen, $text),
                  "primary-foil": ($primaryFoil, $text),
                  "body-grey": ($bodyGrey, $text),
                  "lightish-grey": ($lightishGrey, $text),
                  "background-grey": ($backgroundGrey, $text),
                  "indicator-yellow": ($indicatorYellow, $text),
                  "indicator-green": ($indicatorGreen, $text),
                  "indicator-red": ($indicatorRed, $text),
                  "light-grey":($light, $text),
                  "dark-grey":($darkGrey, white)

);

$link: $primary;

// Update Bulma's global variables
$body-size: 16px;
$radius-large: 12px;
$box-radius: 5px;
// used to set interbox spacing using block()
$box-spacing: 0.5rem ;
$box-padding: 1rem 0.75rem;
$box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1), 0 0 0 0.5px rgba(0,0,0,0.1);
$box-shadow-hover: 0px 48px 48px -17px rgba(0,0,0,0.08);
$input-placeholder-color:$bodyGrey;
$level-item-spacing: 1rem;


// images dimensions available
$dimensions: 16 18 24 32 48 49 64 75 88  96 128;
// dont change stuff for wide screens

$widescreen-enabled: false;
$fullhd-enabled: false;
$gap: 32px;
$desktop: 768px + ($gap * 2);
$section-padding: 1rem 0;

@import "~bulma/sass/utilities/initial-variables.sass";
$navbar-breakpoint: 0;
$navbar-height:3.5rem;
$navbar-item-img-max-height:48px;
$navbar-item-active-color: $link;
$navbar-background-color: $navbarGrey;
$navbar-item-hover-background-color: $navbarGrey;
$navbar-item-color: $text;


// modal stuff
$modal-card-head-background-color: $white;
$modal-card-head-border-bottom: 0;
$modal-card-foot-border-top : 0;
$modal-card-title-size : 10px;
$modal-card-spacing : 20px;
$modal-content-width: 300px;

$card-footer-border-top: 0;
$card-header-shadow: 0;
$card-header-padding: 1rem;
$card-footer-padding: 0.5rem;

@import "~bulma/bulma.sass";

@import '~modularscale-sass/stylesheets/modularscale';

@import '~react-phone-input-2/lib/style.css';

$modularscale: (
  base: 16px,
  ratio: 1.25,
  320px: (
    ratio: 1.25,
  ),
  768px: (
    ratio: 1.414,
  )
);

h5 {
    @include ms-respond(font-size,-1);
}

h4 {
    @include ms-respond(font-size,1);
}

h3 {
    @include ms-respond(font-size,2);
    @extend .has-text-weight-semibold;

}

h2 {

    @include ms-respond(font-size,3);
    @extend .has-text-weight-semibold;
}

h1 {
    @include ms-respond(font-size,4);
    @extend .has-text-weight-bold;
}

h1,h2,h3,h4 {
    padding-bottom:0.9rem;
    line-height:1.4em;
}

h5 {
    padding-bottom:0;
    line-height:1.4em;
}



img{
    @include ms-respond(font-size, -1);
    text-align: center;
}

.label-text {
  margin-bottom: 0.8rem;
}

.is-h5-size {
  @include ms-respond(font-size,-1);
}


.container {
    width:85%;
    padding: 2rem 0rem;
}

.narrower-container {
    width: 85%;
    max-width:37.5rem;
}

.narrowest-container {
    width: 85%;
    max-width:15rem;
    padding: 2rem 0rem;
}


.card-shadow {
  box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1);
  border: 0.5px solid rgba(0,0,0,0.1);
}



.coach-card{
    width: 18em !important;
}


.card {
    width: 20em;
    border-radius: 0.75rem;
    @extend .card-shadow;
    h4 {

        @extend .has-text-weight-bold;
        line-height:1em;
        padding-bottom:0.5em;
    }
    h5 {
        line-height: 1em;
        @extend .has-text-light;
    }
    .card-content{
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2.5rem;
    }
    .level-left {
        width:100%;
        .level-item{
            flex-shrink: 1;
        }
    }
}

.box{
    .level-left{


                flex-shrink: 1;
        .level-item{
            flex-shrink:1

        }
    }

}


.exercise-progress-bar {
  background: $primaryFoil;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.4rem;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  transform: translate3d(0px, 0px, 0px) scaleX(0.333333) scaleY(1);
  transition-duration: 300ms;
}

.swiper-pagination.swiper-pagination-progressbar {
  height: 0.4rem !important;
  background: $progressGrey;
}

.box {
    @include block($box-spacing);
    &:hover {
        box-shadow: $box-shadow-hover;
    }
    h5{
        line-height: 1rem
    }
}


@import 'Timekit.scss';

html {
  height: 100%;
}
body {
  min-height: 100%;
}

.body-bold{
    font-weight:bold;
    line-height: 23px;
}



.modal {
    .card{
        min-width:300px;

        .card-header-title {
            padding-bottom:0.5rem ;

        }
        .card-footer{
            padding: 1rem;
            padding-bottom:2.5rem
        }
        .card-footer-item{
            .is-primary{
                &:hover {
                    background-color:$highlightGreen
                }
                &:active {
                    background-color:$focusGreen
                }

            }

            .is-inverted:hover{
                background-color:$white

                             }
            button{
                flex:1;
            }
            .button.is-inverted{

                padding:0;

            }
        }
    }
}

.group-field {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto auto;
    grid-template-areas: "sidebar questions";
    
    .sidebar {
        grid-area: sidebar;
        button {
            background-color: transparent;
            border: none;
            display: block;
            padding: 3px 0;
        }
    }
    .questions {
        grid-area: questions;
    }
}

.react-tel-input .form-control.phone-input {
    width: 100%;
    min-height: 38px;
    font-size: 16px;
}


.toast-animated{

    animation: appear-disappear 4s ease 0s 1 normal ;

}


@keyframes appear-disappear {
  0%{
	opacity: 0;
	transform: scale3d(0.3, 0.3, 0.3);
  }
  20%{
	opacity: 1;
	transform: scale3d(1,1,1);
  }
  80%{
	opacity: 1;
	transform: scale3d(1,1,1);
  }
  100%{
	opacity: 0;
	transform: scale3d(0.3, 0.3, 0.3);
  }
}


.navbar-item.is-active{
       @extend .has-text-weight-bold;
}

header.dashboard-header {
    position: sticky;
    top: 3.5em;
    z-index: 30;
    
    nav.navbar{
        .container {
        padding-top: 0;
        padding-bottom:0;
        flex-grow:0;
        width:85%;

        }
        > .container .navbar-brand{
            margin-left: 0
        }
            > .container .navbar-menu{
            margin-right: 0
        }
        .button{
            background-color: $navbarGrey !important;
            border:0;
        }
    }
}

header.is-fixed-top, footer.is-fixed-bottom {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
}

header.is-fixed-top {
    top: 0;
}

footer.is-fixed-bottom {
    bottom: 0;
    height:3.5rem;
    .container{
        padding-top:0;
        padding-bottom:0;
        nav {
            height:3.5rem;
            border-top: 2px $progressGrey solid;
        }
    }
}

ul.bulletted-list {
    list-style-type: disc;
    list-style-position: inside;
}

button.link {
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: $family-sans-serif;
    font-size: 0.9rem;
    color: $link;
    text-decoration: underline;
    cursor: pointer;

    &.secondary {
        text-decoration: none;
        color: $bodyGrey;

        &:hover {
            text-decoration: underline;
        }
    }
}

.swiper-slide {
    overflow: hidden;
}

@media screen and (min-width: 832px) {
  .container {
    max-width: 1024px;
  }
}

.is-multiple .select {
  height: 100%;
}

.is-multiple .select::after {
  border-color: transparent !important;
}

.modify-button-row {
  .button {
    margin-right: 0.5rem;
    width: 75px;
  }
}

.message-header {
  background-color: $primary;
}
